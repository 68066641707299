<template lang="pug">
.dashboard-wrapper
  b-row
    b-col
      b-card(no-body, class="pt-2 px-2")
        filters(
          :locationFilterShow="true"
          :roleFilterShow="true"
          :workerFilterShow="true"
          :workerDefaultValue="routeWorker"
          :monthFilterShow="true"
          :yearFilterShow="true"
          @changeFilter="updateFilter"
        )
  
  b-row(v-if="isLoading")
    b-col
      b-card(class="py-5")
        loading(
          :active="true" 
          :is-full-page="false"
          :color="colors.primary"
        )

  div(v-else)
    b-row(v-if="!eventsTotal")
      b-col
        b-card(class="text-center")
          h5(class="mb-0")
            | {{ $t('message.no_confirmations_found') }}

    div(v-else)
      b-row
        b-col(sm="3")
          statistic-card-vertical-total(:statistic="eventsTotal")
        b-col(sm="3")
          statistic-card-vertical-pending(:statistic="eventsPending")
        b-col(sm="3")
          statistic-card-vertical-done(:statistic="eventsDone")

      b-card
        b-table-simple(hover, small, caption-top, responsive)
          b-thead(head-variant="light")
            b-tr
              th {{ $t('message.tableHeader.name') }}
              th {{ $t('message.tableHeader.observer') }}
              th {{ $t('message.tableHeader.process') }}
              th {{ $t('message.tableHeader.date') }}
              th {{ $t('message.tableHeader.status') }}
              th {{ $t('message.tableHeader.action') }}
            b-tr(v-for="(event, index) in events", :key="index")
              b-td {{ event.extendedProps.attendee && event.extendedProps.attendee.name }}
              b-td {{ event.observer }}
              b-td {{ event.extendedProps.process.name }}
              b-td {{ event.date }}
              b-td(:class="event.extendedProps.calendar === 'Realizadas' ? 'text-success' : 'text-warning'") {{ event.computedStatus && event.computedStatus.value }}
              b-td
                navigation-button(v-if="event.extendedProps.calendar && event.extendedProps.calendar !== 'Realizadas'" @clicked="openEventHandlerSidebar(event)")
                view-button(v-if="event.extendedProps.calendar === 'Realizadas'" variant="flat-success" @clicked="router.push({ name: 'habit-confirmation-view', params: { id: event.extendedProps.confirmation._id } })")
  
  //- Sidebar Overlay: Event Handler
  div(
    class="body-content-overlay"
    :class="{'show': isCalendarOverlaySidebarActive}"
    @click="isCalendarOverlaySidebarActive = false"
  )
  calendar-event-handler(
    v-model="isEventHandlerSidebarActive"
    :event="event"
    :clear-event-data="clearEventData"
    :isOnline="isOnline"
    @remove-event="removeEvent"
    @update-event="updateEvent"
  )
</template>

<script>
import { onMounted, ref, computed, onUnmounted } from "@vue/composition-api/dist/vue-composition-api"
import { useRouter } from '@core/utils/utils'
import store from "@/store"
import useNotifications from "@/composables/useNotifications"
import { BTableSimple, BThead, BTr, BTd, BCard } from "bootstrap-vue"
import StatisticCardVerticalTotal from './charts/StatisticCardVerticalTotal.vue'
import StatisticCardVerticalPending from './charts/StatisticCardVerticalPending.vue'
import StatisticCardVerticalDone from './charts/StatisticCardVerticalDone.vue'
import Filters from "@/views/organization/Filters.vue"
import i18n from '@/libs/i18n'
import ViewButton from '@/views/components/Shared/Buttons/ViewButton.vue'
import NavigationButton from '@/views/components/Shared/Buttons/NavigationButton.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import CalendarEventHandler from '@/views/apps/calendar/calendar-event-handler/CalendarEventHandler.vue'
import calendarStoreModule from '@/views/apps/calendar/calendarStoreModule'
import useCommon from '@/views/organization/useCommon'
import { colors } from '@/constants'
import realmConnection from '@/views/habit/realm'

export default {
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BCard,
    StatisticCardVerticalTotal,
    StatisticCardVerticalPending,
    StatisticCardVerticalDone,
    Filters,
    ViewButton,
    NavigationButton,
    Loading,
    CalendarEventHandler,
  },
  setup() {
    const userData = store.state?.userStore?.userData;
    const userRole = userData.role;
    const client_id = userRole !== "admin" ? userData.client.$oid : null;
    const userId = userData.worker_id != null ? userData.worker_id.$oid : null
    const userLocationsQuery = userData.locations?.map(e => ({ _id: e.value }))

    const { handleError, createImprovements } = useCommon()
    const { showSuccessMessage, showErrorMessage } = useNotifications();
    const { router, route } = useRouter()
    const { updateItem, updateItems, getItemsWithAggregate, ObjectId } = realmConnection()
    const events = ref([]);
    const eventsDone = ref(0)
    const eventsPending = ref(0)
    const eventsTotal = ref(0)
    const locationFilter = ref([]);
    const roleFilter = ref([]);
    const routeWorker = route.value.params.id
    const workerFilter = ref(routeWorker ? [routeWorker] : [])
    const zoneFilter = ref([]);
    const agencyFilter = ref([]);
    const now = new Date()
    const monthFilter = ref(now.getMonth())
    const yearFilter = ref(now.getFullYear())
    const isLoading = ref(true)
    const { default_language, commitment_functionality: commitmentFunctionality } = JSON.parse(localStorage.getItem('clientData') || '{}')
    const isOnline = computed(() => store.state.app.isOnline)
    const isEventHandlerSidebarActive = ref(false)
    const isCalendarOverlaySidebarActive = ref(false)
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // Add event listener for connectivity status detection
    onMounted(() => {
      listEvents()
    })

    // UnRegister and remove event listeners on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    });

    const blankEvent = {
      title: '',
      start: '',
      extendedProps: {
        calendar: '',
        process: '',
        attendee: '',
        confirmation: '',
      },
    }
    const event = ref(JSON.parse(JSON.stringify(blankEvent)))
    const clearEventData = () => {
      event.value = JSON.parse(JSON.stringify(blankEvent))
    }

    const updateLocationConditions = (items, category, query) => {
      const filterQuery = items.map(e => ({locations_in: {[category]: e}}))
      query.OR[0].attendee.AND.push({OR: filterQuery})
      query.OR[1].attendee.AND.push({OR: filterQuery})
      query.OR[2].AND.push({organizer: {OR: filterQuery}})
      if (query.OR[3]) query.OR[3].attendee.AND.push({OR: filterQuery})
    }

    async function listEvents() {
      isLoading.value = true

      const firstDayOfCurrentMonth = new Date(yearFilter.value, monthFilter.value, 1)
      const firstDayOfNextMonth = new Date(yearFilter.value, monthFilter.value + 1, 1)

      try {
        const initialQuery = {
          client_id: ObjectId(client_id),
          start: { $gte: firstDayOfCurrentMonth, $lt: firstDayOfNextMonth },
          deleted: { $ne: true },
        }

        if (workerFilter.value.length > 0) {
          initialQuery.attendee = { $in: workerFilter.value.map(e => ObjectId(e)) }
        }

        const finalQuery = {
          $or: [
            { "attendee.deleted": { $ne: true } },
            { "attendee.deleted": true, 'extendedProps.calendar': "Realizadas" },
            { attendee: null }
          ]
        }

        if (userRole === 'supervisor') {
          finalQuery.$or[0]["attendee.supervisors"] = ObjectId(userId)
          finalQuery.$or[1]["attendee.supervisors"] = ObjectId(userId)
          finalQuery.$or[2]["organizer.locations._id"] = { $in: userLocationsQuery.map(e => ObjectId(e._id)) }
          finalQuery.$or[3] = { "attendee._id": userId }
        }

        if (roleFilter.value.length > 0) {
          finalQuery["attendee.roles"] = { $in: roleFilter.value.map(e => ObjectId(e)) }
        }

        if (locationFilter.value.length > 0) {
          finalQuery.$or[0]['attendee.locations._id'] = { $in: locationFilter.value }
          finalQuery.$or[1]['attendee.locations._id'] = { $in: locationFilter.value }
          finalQuery.$or[2]['organizer.locations._id'] = { $in: locationFilter.value }
          if (finalQuery.$or[3]) finalQuery.$or[3]['attendee.locations._id'] = { $in: locationFilter.value }
        }

        if (zoneFilter.value.length > 0) {
          finalQuery.$or[0]['attendee.locations.zone'] = { $in: zoneFilter.value }
          finalQuery.$or[1]['attendee.locations.zone'] = { $in: zoneFilter.value }
          finalQuery.$or[2]['organizer.locations.zone'] = { $in: zoneFilter.value }
          if (finalQuery.$or[3]) finalQuery.$or[3]['attendee.locations.zone'] = { $in: zoneFilter.value }
        }

        if (agencyFilter.value.length > 0) {
          finalQuery.$or[0]['attendee.locations.agency'] = { $in: agencyFilter.value }
          finalQuery.$or[1]['attendee.locations.agency'] = { $in: agencyFilter.value }
          finalQuery.$or[2]['organizer.locations.agency'] = { $in: agencyFilter.value }
          if (finalQuery.$or[3]) finalQuery.$or[3]['attendee.locations.agency'] = { $in: agencyFilter.value }
        }

        const pipeline = [
          { $match: initialQuery },
          { $lookup: { from: 'worker', localField: 'attendee', foreignField: '_id', as: 'attendee', pipeline: [ { $project: { deleted: 1, supervisors: 1, name: 1, locations: 1, roles: 1 } }, { $addFields: { _id: { $toString: "$_id" } } }, { $lookup: { from: 'location', localField: 'locations', foreignField: '_id', as: 'locations', pipeline: [ { $project: { location: 1, zone: 1, agency: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } } ] } },
          { $lookup: { from: 'worker', localField: 'organizer', foreignField: '_id', as: 'organizer', pipeline: [ { $project: { name: 1, locations: 1 } }, { $addFields: { _id: { $toString: "$_id" } } }, { $lookup: { from: 'location', localField: 'locations', foreignField: '_id', as: 'locations', pipeline: [ { $project: { location: 1, zone: 1, agency: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } } ] } },
          { $addFields: { attendee: { $arrayElemAt: ["$attendee", 0] }, organizer: { $arrayElemAt: ["$organizer", 0] } } },
          { $match: finalQuery },
          { $lookup: { from: 'process', localField: 'process', foreignField: '_id', as: 'process', pipeline: [ { $project: { name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } },
          { $lookup: { from: 'confirmation', localField: 'confirmation', foreignField: '_id', as: 'confirmation', pipeline: [ { $addFields: { _id: { $toString: "$_id" } } }, { $lookup: { from: 'worker', localField: 'supervisor', foreignField: '_id', as: 'supervisor', pipeline: [ { $project: { name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } }, { $addFields: { supervisor: { $arrayElemAt: ["$supervisor", 0] } } } ] } },
          { $lookup: { from: 'commitment', localField: 'commitment', foreignField: '_id', as: 'commitment', pipeline: [ { $project: { completed: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } },
          { $lookup: { from: 'improvement', localField: 'improvements', foreignField: '_id', pipeline: [ { $lookup: { from: 'worker', localField: 'assignee', foreignField: '_id', pipeline: [ { $project: { name: 1, email: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ], as: 'assignee' } }, { $addFields: { _id: { $toString: "$_id" }, assignee: { $arrayElemAt: ["$assignee", 0] } } } ], as: 'improvements' } },
          { $addFields: { _id: { $toString: "$_id" }, process: { $arrayElemAt: ["$process", 0] }, confirmation: { $arrayElemAt: ["$confirmation", 0] }, commitment: { $arrayElemAt: ["$commitment", 0] } } },
          { $sort: { start: 1 } }
        ]

        const items = await getItemsWithAggregate({ collection: 'event', pipeline })

        parseEventData(items)
      } catch (error) {
        console.log(error)
        handleError({ error, defaultMessage: i18n.t('message.err_confirmation_list') })
      } finally {
        isLoading.value = false
      }
    }

    function parseEventData(items) {
      let eventsData = []
      let eventsByStatus = { Realizadas: 0, Pendientes: 0 }

      for (const event of items) {
        const date = event.start
        const status = event.extendedProps?.calendar
        const process = event.process?.name
        const attendee = event.attendee?.name

        const payload = {
          id: event._id,
          start: date,
          date: date?.toLocaleDateString(`${default_language || 'en'}-US`),
          title: attendee ? `${attendee} - ${process}` : `${process}`,
          extendedProps: {
            calendar: status,
            process: {
              name: process,
              _id: event.process?._id
            }
          },
          computedStatus: computed(() => i18n.t(status === "Realizadas" ? "done" : "message.tableHeader.pending_status")),
          observer: event.confirmation?.supervisor?.name
        }

        if (attendee) {
          payload.extendedProps.attendee = {
            name: attendee,
            _id: event.attendee._id
          }
        }

        if (event.confirmation) {
          payload.extendedProps.confirmation = {
            _id: event.confirmation._id,
          }
        }

        eventsData.push(payload)

        if (status === "Realizadas") eventsByStatus["Realizadas"] += 1;
        else eventsByStatus["Pendientes"] += 1;
      }

      eventsDone.value = eventsByStatus["Realizadas"]
      eventsPending.value = eventsByStatus["Pendientes"]
      eventsTotal.value = eventsByStatus["Realizadas"] + eventsByStatus["Pendientes"]

      eventsData.sort(function (a, b) {
        // Done events go after overdue and pending events
        if (a.extendedProps.calendar === "Realizadas" && b.extendedProps.calendar !== "Realizadas") return 1
        if (a.extendedProps.calendar !== "Realizadas" && b.extendedProps.calendar === "Realizadas") return -1
        return 0
      })

      events.value = eventsData
    }

    const openEventHandlerSidebar = (eventData) => {
      event.value = {...eventData}
      isEventHandlerSidebarActive.value = true
    }

    const updateEvent = async eventData => {
      try {
        const query = { _id: ObjectId(eventData.id) }

        const payload = {
          attendee: eventData.extendedProps.attendee ? ObjectId(eventData.extendedProps.attendee._id) : null,
          process: eventData.extendedProps.process ? ObjectId(eventData.extendedProps.process._id) : null,
        }

        if (event.extendedProps.metadata) {
          payload.metadata = event.extendedProps.metadata
        }

        if (event.extendedProps.participants) {
          payload.participants = event.extendedProps.participants.map(e => ObjectId(e))
        }

        let newImprovementIds = null
        if (event.extendedProps.improvements?.length) {
          let allImprovementIds = event.extendedProps.improvements.filter(e => e._id).map(e => e._id)
          const newImprovements = event.extendedProps.improvements.filter(e => !e._id)
          try {
            newImprovementIds = await createImprovements(newImprovements, "event", null, false)
            if (newImprovementIds?.length) allImprovementIds = [...allImprovementIds, ...newImprovementIds]
          } catch (error) {
            console.log(error)
          }
          if (allImprovementIds.length) payload.improvements = allImprovementIds.map(e => ObjectId(e))
        } else {
          payload.improvements =  []
        }

        payload.start = event.start instanceof Date ? event.start : new Date(event.start)
        payload.end = event.end instanceof Date ? event.end : new Date(event.end)

        if (!commitmentFunctionality || event.end <= event.start) {
          payload.end = new Date(payload.start)
          payload.end.setHours(payload.end.getHours() + 1 )
        }

        // Set calendar status
        payload.extendedProps = { calendar: event.extendedProps.calendar }
        if (event.extendedProps.calendar !== 'Realizadas') {
          payload.start.getTime() < now.getTime() ? payload.extendedProps.calendar = 'Vencidas' : null
          payload.start.getTime() >= now.getTime() ? payload.extendedProps.calendar = 'Pendientes' : null
          payload.start.getTime() >= firstDayOfNextMonth.getTime() ? payload.extendedProps.calendar = 'Futuras' : null
        }
        
        payload.modifiedAt = new Date()

        const action = { $set: payload }

        await updateItem({ collection: 'event', query, action })
        
        showSuccessMessage(i18n.t('message.Event_updated'))
        listEvents()
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.event_update_error'))
      } finally {
        isEventHandlerSidebarActive.value = false
      }
    }

    const removeEvent = async ({ eventData, deletedJustification, improvementsToDelete }) => {
      const action = { $set: { deleted: true } }

      try {
        // Delete commitments associated with problem solving
        if (eventData.extendedProps.improvements?.length) {
          const updateQuery = { _id: { $in: eventData.extendedProps.improvements.map(e => {
            if (!e._id) return null
            return ObjectId(e._id)
          }) } }

          await updateItems({ collection: 'improvement', query: updateQuery, action })
        }

        // Delete commitments already flagged for deletion
        if (improvementsToDelete?.length) {
          const updateQuery = { _id: { $in: improvementsToDelete.map(e => ObjectId(e._id)) } }
          
          await updateItems({ collection: 'improvement', query: updateQuery, action })
        }
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.improvement_update_error'))
      }

      try {
        // Delete event
        const query = { _id: ObjectId(eventData.id) }
        const eventAction = { $set: {
          deleted: true,
          deletedJustification,
          deletedBy: ObjectId(userId)
        } }

        await updateItem({ collection: 'event', query, action: eventAction })

        showSuccessMessage(i18n.t('message.Event_removed'))
        listEvents()
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.event_remove_error'))
      } finally {
        isEventHandlerSidebarActive.value = false
      }
    }

    function updateFilter(data) {
      locationFilter.value = data.locationFilter;
      roleFilter.value = data.roleFilter;
      workerFilter.value = data.workerFilter;
      monthFilter.value = data.monthFilter;
      yearFilter.value = data.yearFilter;
      zoneFilter.value = data.zoneFilter;
      agencyFilter.value = data.agencyFilter;

      listEvents();
    }

    return {
      events,
      eventsDone,
      eventsPending,
      eventsTotal,
      updateFilter,
      userRole,
      isLoading,
      router,
      isOnline,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      updateEvent,
      removeEvent,
      isEventHandlerSidebarActive,
      openEventHandlerSidebar,
      routeWorker,
      colors
    };
  },
};
</script>

<style lang="scss" scoped>
</style>